body {
    background-color: #f8f8f8; 
    color: #333;          
    font-family: "Times New Roman", Times, serif;
    font-size: 13px; 
}

.accordion {
    background-color: #fff; 
    color: #333;       
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.active, .accordion:hover {
    background-color: #f0f0f0; 
}

.panel {
    padding: 0 18px;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    color: #333; 
}

.panel-inner {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.subaccordion {
    background-color: #e8e8e8;
    color: #333;        
    cursor: pointer;
    padding: 10px;
    height: 400px;
    margin: 5px;
    width: auto;
    border: none;
    text-align: left;
    outline: none;
    font-size: 14px;
    transition: 0.4s;
    display: inline-block;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.subpanel {
    background-color: #f5f5f5; 
    padding: 10px;
    display: none;
    max-height: 400px; 
    overflow-y: auto;
    box-shadow: inset 0 2px 5px rgba(0,0,0,0.1);
}

.subpanel.show {
    display: block;
}

a {
    color: #007bff; 
    text-decoration: none; 
    padding: 5px; 
}

a:hover {
    color: #0056b3;
    text-decoration: underline; 
}

.read-checkbox {
    margin-left: 10px;
}


.publication-details {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .publication-details p {
    margin: 5px 0;
  }
  
  .copy-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .copy-button:hover {
    background-color: #45a049;
  }
  
  .non-originals {
    padding: 10px;
    background-color: #f9f9f9;
    margin-top: 10px;
  }
  
  .non-originals strong {
    display: block;
    margin-bottom: 5px;
  }